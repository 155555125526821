.app {
    background: #fff;
    box-sizing: border-box;
    height: 100%;
}

.blogCard  {
    border-radius: 8px 8px 0 0;
}

.paginateBtns, .previousBtn, .nextBtn {
    padding: 10px 14px;
    border-radius: 4px;
    background-color: #60164f;  
    color: #fff; 
    cursor: not-allowed;
}

.paginateBtns {
    cursor: pointer;
}

.previousBtn {
    cursor: pointer;
}

.nextBtn {
    cursor: pointer;
}

.disabledBtn {
    opacity: 0.7;
    cursor: not-allowed !important;
}

.paginateBtns:hover, .previousBtn:hover, .nextBtn:hover, .activeBtn {
    background-color: #9E3589;
}



