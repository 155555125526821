@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400;1,700&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;


html,
body,
#root,
.app {
    margin: 0;
    padding: 0;
    box-sizing: border-box ;
    background-color: #fff;
}